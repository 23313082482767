import React from 'react';
import { List, Spoiler, ThemeIcon } from '@mantine/core';
import { CircleCheck } from 'tabler-icons-react';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import adamson from '../../assets/images/departments/oral-surg/adamson.png';
import adeyemo from '../../assets/images/departments/oral-surg/adeyemo.png';
import arotiba from '../../assets/images/departments/oral-surg/arotiba.png';
import gbotolorun from '../../assets/images/departments/oral-surg/gbotolorun.jpg';
import james from '../../assets/images/departments/oral-surg/james.jpg';
import ladeinde from '../../assets/images/departments/oral-surg/ladeinde.jpg';
import ogunlewe from '../../assets/images/departments/oral-surg/ogunlewe.jpg';
import team1 from '../../assets/images/team-1-1.jpg';
import oluwarotimi from '../../assets/images/departments/oral-surg/oluwarotimi.png';

const OralSurgery = () => {
  const services = [
    {
      name: 'Extractions including Surgical Dentoalveolar Surgeries',
    },
    {
      name:
        'Management of Facial Trauma (Mandible, Maxilla, Midface, Nasal Complex, Orbit and Frontal bone)',
    },
    {
      name: 'Management of Tumors of Jaws',
    },
    {
      name: 'Reconstruction of Jaws defects',
    },
    {
      name:
        'Management of Cleft Lip and Palate (Cheilorrhaphy, Palatorrhaphy, Velopharyngeal dysfunction [speech] surgery, Alveolar bone grafts and Distraction Osteogenesis)',
    },
    {
      name: 'Orthognathic surgery',
    },
    {
      name: 'Implantology with/without bone graft or augmentation',
    },
    {
      name: 'Preprosthetic surgery and other soft tissue oral surgeries',
    },
  ];

  return (
    <Layout pageTitle='Department of Oral And Maxillofacial Surgery | LUTH'>
      <NavOne />
      <PageHeader title='Department of Oral And Maxillofacial Surgery' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Oral And Maxillofacial Surgery
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The vision of the department of oral and Maxiollofacial
                        Surgery is to be the topmost academic department of Oral
                        and Maxillofacial Surgery in Sub-Sahara Africa with
                        regards to teaching, learning, research, patient care
                        and service to community. <br />
                        <br />
                        Our set missions are; to produce competent dental
                        surgeons who will provide dento-alveolar surgery
                        services at the primary, secondary and tertiary levels
                        of health care delivery I Nigeria and other parts of the
                        world; to produce dental surgeons who will provide
                        leadership for the Dental team in dento-alveolar
                        surgery; to produce dental surgeons who will be capable
                        of completing specialist training and research in oral
                        and maxillofacial surgery anywhere in the world; to
                        produce dental surgeons who will engage in life-long
                        learning in oral surgery; and to produce dental
                        graduates who will be leaders of the dental profession
                        nationally and internationally. <br />
                        <br />
                        We provide services to the public which include simple
                        and surgical extraction of impacted teeth, removal of
                        jaw/facial tumors and reconstruction, open and closed
                        treatment of fractures of the facial bones, repair of
                        cleft lip and palate and other craniofacial anomalies,
                        speech surgery, placement of dental implants, corrective
                        jaw surgeries etc.
                        <br />
                        <br />A referral is required for patients who would like
                        to be seen in our surgical outpatient clinics, however,
                        walk in patients can also be attended to via the oral
                        diagnosis clinic of the department of preventive
                        dentistry. The surgical outpatient clinic is located on
                        the second floor of the Accident and Emergency building
                        (SOP II) with clinics holding on Monday and Thursday 8am
                        and cleft clinic Tuesday 12 noon. The minor ops clinic
                        is located in the old dental block.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <List
                      spacing='xs'
                      size='sm'
                      center
                      icon={
                        <ThemeIcon color='teal' size={24} radius='xl'>
                          <CircleCheck size={16} />
                        </ThemeIcon>
                      }
                    >
                      {services.map((data) => (
                        <List.Item key={data.name}>{data.name}</List.Item>
                      ))}
                    </List>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={gbotolorun} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Olalekan. M. Gbotolorun</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Current HOD Professor/ Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FMCDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Head and Neck Oncology and Dentoalveolar Surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={arotiba} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Godwin T. Arotiba</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FMCDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Head and Neck Oncology; Cranio-maxillofacial Trauma
                            and reconstructive surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ladeinde} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Akinlola L. Ladeinde</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FMCDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Head and Neck Oncology; Orofacial Cleft Repair
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ogunlewe} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. M. O. Ogunlewe</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orofacial Cleft surgery; Maxillofacial
                            Reconstruction
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adeyemo} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Prof. Wasiu. L. Adeyemo</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FMCDS, FWACS, Ph.D
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & Maxillofacial Surgery; Orofacial cleft
                            surgery; maxillofacial reconstruction
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={james} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Associate Prof. Olutayo James</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, M.Sc., FMCDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Craniofacial Cleft, Cranio-maxillofacial Trauma
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oluwarotimi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Associate Professor Akanbi C. Oluwarotimi</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Honorary Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FMCDS, FICS, FICIO
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Dentoalveolar surgery, Maxillofacial oncology & Implantology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adamson} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olawale. O. Adamson</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                             Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FMCDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Velopharyngeal surgery; Orofacial Cleft; Implantology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adamson} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Olawale. O. Adamson</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                             Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS, FMCDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Velopharyngeal surgery; Orofacial Cleft; Implantology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Adegbayi. A. Adekunle</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                             Consultant
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BChD, FMCDS, FWACS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Orthognathic Surgery; Orofacial cleft; Implantology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ebenezer A. Ajokolo</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                             Senior Registrar I
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BChD
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial Surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ochuko Ebrorhie</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Senior Registrar II
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial Surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Uchenna P. Egbunah</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Senior Registrar II
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & Maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. (Mrs.) Racheal O. Babayomi</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & Maxillofacial Surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Haningh F. Ngalato</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Benjamin U. Ewanosetin</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Jamal A. Ilupeju</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Eberechukwu G. Onyenekwu</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & Maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Abdul-Kabir A. Yahya-Imam</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & Maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Albert O. Onyeahialam</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Felix O. Faniran</h3>
                          <p>
                            <span className='detail-head'>Position:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BChD
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Sunday O. Atoyebi</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BChD
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Ikechukwu Albert</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Registrar
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            BDS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Oral & maxillofacial surgery
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={team1} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Mr. Abel I. Imoekor</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Chief Clerical Officer
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            NECO
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            ADMINISTRATION
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:omfs@luth.gov.ng'>omfs@luth.gov.ng</a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default OralSurgery;
